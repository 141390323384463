import { Component } from '@angular/core';

@Component({
  selector: 'app-cancel-confirm',
  templateUrl: './cancel-confirm.component.html',
  styleUrls: ['./cancel-confirm.component.css']
})
export class CancelConfirmComponent {

}
