<h1>Dealer Status Page - <span *ngIf="sub.status == 404" class="name">Not</span> Enrolled</h1>

<ng-template [ngIf]="loading">
    <section>
        <p>Getting subscription information...</p>
    </section>
</ng-template>

<ng-template [ngIf]="!sub.status">
<section>
<h2>{{dealer.name}} <code>{{dealer.country3}}{{dealer.pa_code}}</code> is enrolled!</h2>
<ul>
    <li>Enrolled On: {{enrolled}}
    <li>PA Code: {{sub.siteCode}}
    <li>Technicians: {{dealer.tech_count}}
    <li>Cost/technician: ${{prices.per_tech.toFixed(2)}}
    <li>Monthly charges: ${{prices.monthly.toFixed(2)}}
</ul>
<p><button [routerLink]="['/cancel']">Cancel Subscription</button>
</section>
</ng-template>

<ng-template [ngIf]="sub.status == 404">
<section>
    <p>{{dealer.name}} <code>{{dealer.country3}}{{dealer.pa_code}}</code> is not enrolled!
    <p>This dealership won't be able to use the IDS (Integrated Diagnostic System) software.</p>
    <p><button [routerLink]="['/enroll']">Enroll Now</button>
</section>
</ng-template>

<ng-template [ngIf]="sub.status && sub.status != 404">
<section>
    <p><img src="./assets/loader.gif"> Retrieving enrollment status</p>
    <p><button (click)="retry()">Retry</button>
</section>
</ng-template>
