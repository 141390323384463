import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { PriceTier } from '../data/price-tier';

@Injectable({
  providedIn: 'root',
})
export class PriceTierService {
  private priceTierUrl = `${environment.apiUrl}/api/PriceTier`;

  constructor(private http: HttpClient) { }

  getPriceTiers(): Observable<PriceTier[]> {
    const url = `${this.priceTierUrl}/`;
    return this.http.get<PriceTier[]>(url)
      .pipe(
        tap(_ => this.log('fetched price tiers')),
        catchError(this.handleError<PriceTier[]>('getPriceTiers'))
      );
  }

  updatePriceTier(tier: PriceTier): Observable<PriceTier> {
    const url = `${this.priceTierUrl}/`;
    return this.http.put<PriceTier>(url, tier)
      .pipe(
        tap(_ => this.log('Updated price tier')),
        catchError(this.handleError<PriceTier>('updatePriceTier'))
      );
  }

  // getTier(item_number: string): Observable<Tier> {
  //   const url = `${this.tierUrl}/${item_number}`;
  //   return this.http.get<Tier>(url)
  //     .pipe(
  //       tap(_ => this.log('fetched tier')),
  //       catchError(this.handleError<Tier>('getTier'))
  //     );
  // }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}