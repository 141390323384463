import { Component } from '@angular/core';
import { ReportService } from '../services/report.service';
import { Revenue } from '../data/revenue';
import { FuncsService } from '../services/funcs.service';

@Component({
  selector: 'app-report-tiers',
  templateUrl: './report-tiers.component.html',
  styleUrls: ['./report-tiers.component.css']
})
export class ReportTiersComponent {
  public loading = true;
  report: Revenue[] = [];

  constructor(
    private reportService: ReportService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkAdmin();
    
    this.reportService.getDealerTiers().subscribe(report => {
      this.report = report;
      this.loading = false;
    });
  }
}
