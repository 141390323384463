import { Component } from '@angular/core';
import { FuncsService } from '../services/funcs.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent {
  constructor(
    private funcs: FuncsService
  ) {}

  ngOnInit() {
    this.funcs.checkAdmin();
  }
}
