export interface Dealer {
    pa_code: string;
    name: string;
    tech_count: number;
    country: string;
    country3: string;
    subscribed: string;
    active: string;
}

export const DEALERS: Dealer[] = [];
export const DealerCurrent: Dealer =
    {pa_code: "", name: "", tech_count: 0, country: "US", country3: "USA", subscribed: "N", active: "N"}
;