import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Dealer } from '../data/dealer';

@Injectable({
  providedIn: 'root',
})
export class DealerService {
  private dealerUrl = `${environment.apiUrl}/api/Dealers`;

  constructor(private http: HttpClient) { }

  getDealers(): Observable<Dealer[]> {
    const url = `${this.dealerUrl}/`;
    return this.http.get<Dealer[]>(url)
      .pipe(
        tap(_ => this.log('fetched dealers')),
        catchError(this.handleError<Dealer[]>('getDealers'))
      );
  }

  getDealer(pa_code: string): Observable<Dealer> {
    const url = `${this.dealerUrl}/FCT${pa_code}`;
    return this.http.get<Dealer>(url)
      .pipe(
        tap(_ => this.log('fetched dealers')),
        catchError(this.handleError<Dealer>('getDealer'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}