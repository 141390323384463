import { Injectable } from '@angular/core';
import { Dealer, DealerCurrent } from '../data/dealer';
import { Router } from '@angular/router';
import { Customer, CustomerCurrent } from '../data/customer';

@Injectable({
  providedIn: 'root'
})

export class FuncsService {
  constructor(private router: Router) { }
  
  currentDealer(dealer: Dealer): void {
    var d;
    if (!dealer || !dealer.pa_code) {
      d = window.localStorage.getItem("dealer");
      if (!d) {
        // or really, redirect 
        this.router.navigateByUrl('/home');
        return;
      }
      d = JSON.parse(d);
      if (!d.pa_code) {
        this.router.navigateByUrl('/home');
        return;
      }

      DealerCurrent.pa_code = d.pa_code;
      DealerCurrent.name = d.name;
      DealerCurrent.country = dealer.country;
      DealerCurrent.country3 = dealer.country3;
      DealerCurrent.tech_count = d.tech_count;
      DealerCurrent.subscribed = d.subscribed;
      DealerCurrent.active = d.active;
    }
  }

  setDealer(dealer: Dealer): void {
    DealerCurrent.pa_code = dealer.pa_code;
    DealerCurrent.name = dealer.name;
    DealerCurrent.country = dealer.country;
    DealerCurrent.country3 = dealer.country3;
    DealerCurrent.tech_count = dealer.tech_count;
    DealerCurrent.subscribed = dealer.subscribed;
    DealerCurrent.active = dealer.active;

    // persist data
    var dealerStore = {
      pa_code: DealerCurrent.pa_code,
      name: DealerCurrent.name,
      country: DealerCurrent.country,
      country3: DealerCurrent.country3,
      tech_count: DealerCurrent.tech_count,
      subscribed: DealerCurrent.subscribed,
      active: DealerCurrent.active
    };
    window.localStorage.setItem("dealer", JSON.stringify(dealerStore));
  }

  setCustomer(customer: Customer): void {
    CustomerCurrent.username = customer.username;
    CustomerCurrent.pa_code = customer.pa_code;
    CustomerCurrent.type = customer.type;
    CustomerCurrent.name = customer.name;
    CustomerCurrent.country = customer.country;
    CustomerCurrent.role = customer.role;
    CustomerCurrent.active = customer.active;
    CustomerCurrent.reported_terminated = customer.reported_terminated;
    CustomerCurrent.authenticated = customer.authenticated;

    // persist data
    var customerStore = {
      username: CustomerCurrent.username,
      pa_code: CustomerCurrent.pa_code,
      type: CustomerCurrent.type,
      name: CustomerCurrent.name,
      country: CustomerCurrent.country,
      role: CustomerCurrent.role,
      active: CustomerCurrent.active,
      reported_terminated: CustomerCurrent.reported_terminated,
      authenticated: CustomerCurrent.authenticated
    };
    window.localStorage.setItem("customer", JSON.stringify(customerStore));

    if (customer.role === "DEALER") {
      //set dealer
    }
  }

  currentCustomer(customer: Customer): void {
    var c;
    if (!customer || !customer.authenticated || customer.authenticated === "N") {
      c = window.localStorage.getItem("customer");

      if (!c) {
        // or really, redirect 
        this.router.navigateByUrl('/home');
        return;
      }
      c = JSON.parse(c);
      if (!c.pa_code && c.role !== "ADM") {
        this.router.navigateByUrl('/home');
        return;
      }

      CustomerCurrent.username = c.username;
      CustomerCurrent.pa_code = c.pa_code;
      CustomerCurrent.type = c.type;
      CustomerCurrent.name = c.name;
      CustomerCurrent.country = c.country;
      CustomerCurrent.role = c.role;
      CustomerCurrent.active = c.active;
      CustomerCurrent.reported_terminated = c.reported_terminated;
      CustomerCurrent.authenticated = c.authenticated;
    }
  }

  logOutCustomer(): void {
    window.localStorage.setItem("customer", "{}");

    CustomerCurrent.username = "";
    CustomerCurrent.pa_code = "";
    CustomerCurrent.type = "";
    CustomerCurrent.name = "";
    CustomerCurrent.country = "";
    CustomerCurrent.role = "";
    CustomerCurrent.active = "";
    CustomerCurrent.reported_terminated = "";
    CustomerCurrent.authenticated = "";

    // clear dealer as well
    DealerCurrent.pa_code = "";
    DealerCurrent.name = "";
    DealerCurrent.tech_count = 0;
    DealerCurrent.subscribed = "";
    DealerCurrent.active = "";

    // persist data
    var dealerStore = {
      pa_code: "",
      name: "",
      tech_count: 0,
      subscribed: "",
      active: "DealerCurrent.active"
    };
    window.localStorage.setItem("dealer", JSON.stringify(dealerStore));

    this.router.navigateByUrl('/home');
  }

  checkAdmin(): void {
    this.currentCustomer(CustomerCurrent);
    if (CustomerCurrent.authenticated !== "Y") {
      this.router.navigateByUrl('/home');
    }
    if (CustomerCurrent.role === "DEALER") {
      this.router.navigateByUrl('/status-enrolled');
    }
    if (CustomerCurrent.role !== "ADM") {
      this.router.navigateByUrl('/home');
    }
  }

  now(): string {
    var timeNow = new Date();
    var hour = String(timeNow.getHours()).padStart(2, "0");
    var min = String(timeNow.getMinutes()).padStart(2, "0");

    // this.previousSibling.value = `${hour}:${min}`;
    return `${hour}:${min}`;
  }

  dateFormat(dateString: string): string {
      var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      if (!dateString) {
          return "";
      }
      
      var d = new Date(dateString);
      if (d instanceof Date) {
          return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
      }
      return "";
  }
}
