import { Component } from '@angular/core';
import { Subscription } from '../data/subscription';
import { FordService } from '../services/ford.service';
import { PriceTier } from '../data/price-tier';
import { PriceTierService } from '../services/price-tier.service';
import { Dealer, DealerCurrent } from '../data/dealer';
import { Price } from '../data/price';
import { FuncsService } from '../services/funcs.service';

@Component({
  selector: 'app-status-enrolled',
  templateUrl: './status-enrolled.component.html',
  styleUrls: ['./status-enrolled.component.css']
})
export class StatusEnrolledComponent {
  sub: Subscription = {
    createdAt: "",
    siteCode: "",
    maxAllowedSessions: 0,
    concurrentSessions: 0,
    expiresAt: "",
    status: 200
  };

  prices: Price = {
    per_tech: 0,
    monthly: 0
  };

  public loading = true;
  public price = 0;
  public monthly = 0;
  public enrolled = "";
  dealer: Dealer = DealerCurrent;
  priceTiers: PriceTier[] = [];

  constructor(
    private fordService: FordService,
    private priceTierService: PriceTierService,
    private funcs: FuncsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.funcs.currentDealer(this.dealer);
    this.getSubscription();
    this.loading = true;
  }

  retry (): void {
    this.getSubscription();
    this.loading = true;
  }

  getSubscription(): void {
    this.fordService.getSubscription(`${this.dealer.country3}${this.dealer.pa_code}`).subscribe(sub => {
      this.sub = sub;
      this.priceTierService.getPriceTiers().subscribe(priceTiers => {
        this.priceTiers = priceTiers;
        this.priceTiers = this.priceTiers.sort((a, b) => {
          return a.qty - b.qty;
        });

        this.priceTiers.forEach(pt => {
          if (pt.qty < this.dealer.tech_count) {
            this.prices.per_tech = pt.price;
          }
        });
        this.prices.monthly = this.prices.per_tech * this.dealer.tech_count / 12;
        this.loading = false;
      });

      this.enrolled = new Date(this.sub.createdAt).toDateString();
    });
  }
}
