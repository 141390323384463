import { Component } from '@angular/core';
import { FuncsService } from '../services/funcs.service';

@Component({
  selector: 'app-home-reports',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent {
  constructor(
    private funcs: FuncsService
  ) {}

  ngOnInit() {
    this.funcs.checkAdmin();
  }

  logOut() {
    this.funcs.logOutCustomer();
  }
}
