import { Component } from '@angular/core';
import { FordService } from '../services/ford.service';
import { Router } from '@angular/router';
import { Dealer, DealerCurrent } from '../data/dealer';
import { FuncsService } from '../services/funcs.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelComponent {
  dealer: Dealer = DealerCurrent;

  constructor(
    private fordService: FordService,
    private funcs: FuncsService,
    private _router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.funcs.currentDealer(this.dealer);
  }

  cancelSubscription(): void {
    var dealerCode = `${DealerCurrent.country3}${DealerCurrent.pa_code}`;
    this.fordService.deleteSubscription(dealerCode).subscribe(
      () => this._router.navigateByUrl('/status-enrolled')
    );
  }
}
