import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Customer } from '../data/customer';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private customerUrl = `${environment.apiUrl}/api/Customers`;

  constructor(private http: HttpClient) { }

  getCustomer(username: string): Observable<Customer> {
    const url = `${this.customerUrl}/${username}`;
    return this.http.get<Customer>(url)
      .pipe(
        tap(_ => this.log('fetched customer')),
        catchError(this.handleError<Customer>('getCustomer'))
      );
  }

  getCustomerAuth(username: string, password: string): Observable<Customer> {
    const url = `${this.customerUrl}/${username}`;
    return this.http.post<Customer>(url, {password: password})
      .pipe(
        tap(_ => this.log('fetched customer auth')),
        catchError(this.handleError<Customer>('getCustomerAuth'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}