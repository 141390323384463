import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { StatusEnrolledComponent } from './status-enrolled/status-enrolled.component';
import { AdminDealerComponent } from './admin-dealer/admin-dealer.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { CancelComponent } from './cancel/cancel.component';
import { CancelConfirmComponent } from './cancel-confirm/cancel-confirm.component';
import { ContactComponent } from './contact/contact.component';
import { EnrollComponent } from './enroll/enroll.component';
import { EnrollConfirmComponent } from './enroll-confirm/enroll-confirm.component';
import { FaqComponent } from './faq/faq.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportTiersComponent } from './report-tiers/report-tiers.component';
import { ReportMonthlyRevenueComponent } from './report-monthly-revenue/report-monthly-revenue.component';
import { ReportEnrolledComponent } from './report-enrolled/report-enrolled.component';
import { AutoLoginComponent } from './auto-login/auto-login.component';
import { TiersComponent } from './tiers/tiers.component';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'status-enrolled', component: StatusEnrolledComponent},
  {path: 'admin-dealer', component: AdminDealerComponent},
  {path: 'admin-home', component: AdminHomeComponent},
  {path: 'cancel', component: CancelComponent},
  {path: 'cancel-confirm', component: CancelConfirmComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'enroll', component: EnrollComponent},
  {path: 'enroll-confirm', component: EnrollConfirmComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'reports', component: ReportsComponent},
  {path: 'report-tiers', component: ReportTiersComponent},
  {path: 'report-monthly-revenue', component: ReportMonthlyRevenueComponent},
  {path: 'report-enrolled', component: ReportEnrolledComponent},
  {path: 'auto-login', component: AutoLoginComponent},
  {path: 'tiers', component: TiersComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }