<h1><a href="/admin-home">Admin</a> - Price Tiers</h1>

<section>
    <p>{{loading}}</p>
    <table class="data">
        <thead><tr><td>Min</td><td>$</td><td>CAN</td><td></td></tr></thead>
        <tbody>
            <tr *ngFor="let priceTier of priceTiers; let idx = index; ">
                <td>{{priceTier.qty}}</td>
                <td>$ <input type="number" [(ngModel)]="priceTiers[idx].price"></td>
                <td>$ <input type="number" [(ngModel)]="priceTiers[idx].price_canadian_dollar"></td>
                <td><button (click)="updateQty(idx)">Update Tier Min {{priceTier.qty}}</button></td>
            </tr>
        </tbody>
    </table>
</section>
