<h1><a href="/admin-home">Admin</a> - Dealers</h1>

<section>
    <p><label>Dealer: 
        <input type="text" id="admin-dealer" [(ngModel)]="search" (keyup)="displayDealers()" autofocus="autofocus">
    </label></p>
    <p><button (click)="download()">Download Report Data</button></p>
</section>

<section>
    <p>{{loading}}</p>
    <table class="data">
        <thead><tr><td>PA Code</td><td>Name</td><td>Country</td><td>Techs</td><td>Enrolled</td><td></td></tr></thead>
        <tbody>
            <tr *ngFor="let dealer of displayedDealers">
                <td>{{dealer.pa_code}}</td>
                <td>{{dealer.name}}</td>
                <td>{{dealer.country3}}</td>
                <td class="number">{{dealer.tech_count}}</td>
                <th [attr.data-pa]="dealer.pa_code" *ngIf="dealer.subscribed == 'Y'"><img src="./assets/loader.gif"></th>
                <th [attr.data-pa]="dealer.pa_code" *ngIf="dealer.subscribed != 'Y'">{{dealer.subscribed}}</th>
                <td><button (click)="fly(dealer)">Be {{dealer.pa_code}}</button></td>
            </tr>
        </tbody>
    </table>
</section>
