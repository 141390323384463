<h1><a href="/admin-home">Admin</a> - Revenue by Month</h1>

<section>
    <p>{{loading}}</p>
    <table class="data">
        <thead><tr><td>Month</td><td>Revenue</td></tr></thead>
        <tbody>
            <tr *ngFor="let row of report">
                <td>{{row.year}}-{{row.month}}</td><td class="number">${{row.revenue.toFixed(2)}}</td>
            </tr>
        </tbody>
    </table>
</section>
