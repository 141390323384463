<h1>Enroll</h1>

<section>
<p>Clicking "Enroll Now" below will subscribe you to the Ford Diagnostic Enrollment Program.
<ul>
    <li>Name: {{dealer.name}}
    <li>Country: {{dealer.country3}}
    <li>PA Code: {{sub.siteCode}}
    <li>Your number of technicians: {{dealer.tech_count}}
    <li>Cost per technician: ${{prices.per_tech.toFixed(2)}}
    <li>Monthly cost: ${{prices.monthly.toFixed(2)}}
    <li>You will be able to have up to {{sub.concurrentSessions}} concurrent sessions.
    <li>You will be allowed {{sub.maxAllowedSessions}} maximum. (If a tech is working on multiple vehicles.)
</ul>
<p><button (click)="addSubscription()">Enroll Now</button>
</section>
