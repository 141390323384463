import { Component } from '@angular/core';
import { FuncsService } from './services/funcs.service';
import { Customer, CustomerCurrent } from './data/customer';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'IDS Enrollment';
  user_name = "";
  full_name = "";
  dealer = "";
  navClass = "";
  prod: boolean = environment.production;

  constructor(
    private funcs: FuncsService
  ) {}

  ngOnInit() {
    this.funcs.currentCustomer(CustomerCurrent);
    if (CustomerCurrent) {
      this.user_name = CustomerCurrent.username;
      this.full_name = CustomerCurrent.name;
      this.dealer = CustomerCurrent.pa_code;
    }
  }

  logout(): void {
    this.funcs.logOutCustomer();
  }
  showNav() {
    this.navClass = (!this.navClass) ? "active" : "";
  }
  closeNavIfOpen(): void {
    this.navClass = "";
  }
}