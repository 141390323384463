<h1><a href="/admin-home">Admin</a> - Total Dealers by Tier</h1>

<section>
    <table class="data">
        <thead><tr><td>Tier</td><td>Dealers</td></tr></thead>
        <tbody>
            <tr *ngFor="let row of report">
                <td>{{row.date}}</td><td class="number">{{row.revenue.toFixed(0)}}</td>
            </tr>
        </tbody>
    </table>
</section>
