export interface Customer {
    username: string;
    pa_code: "";
    type: string;
    name: string;
    country: string;
    country3: string;
    role: string;
    active: string;
    reported_terminated: string;
    authenticated: string;
}

export const Customers: Customer[] = [];
export const CustomerCurrent: Customer = {
    username: "",
    pa_code: "",
    type: "",
    name: "",
    country: "",
    country3: "",
    role: "",
    active: "",
    reported_terminated: "",
    authenticated: "N"
};
