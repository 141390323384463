import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Dealer, DEALERS, DealerCurrent } from '../data/dealer';
import { FuncsService } from '../services/funcs.service';
import { Router } from '@angular/router';
import { DealerService } from '../services/dealer.service';
import { FordService } from '../services/ford.service';

@Component({
  selector: 'app-report-enrolled',
  templateUrl: './report-enrolled.component.html',
  styleUrls: ['./report-enrolled.component.css']
})

export class ReportEnrolledComponent {
  public loading = "Loading...";
  dealers = DEALERS;
  displayedDealers = this.dealers;
  search = "";
  enrolled = "E";
  queryEnrolled: string = "";

  constructor(
    private dealerService: DealerService,
    private router: Router,
    private route: ActivatedRoute,
    private fordService: FordService,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkAdmin();
    this.loading = "Loading...";

    this.queryEnrolled = this.route.snapshot.queryParamMap.get('enrolled') || '';
    if (["E", "U", "A"].indexOf(this.queryEnrolled) > -1) {
      this.enrolled = this.queryEnrolled;
    }
    this.dealerService.getDealers().subscribe(dealers => {
      this.dealers = dealers;
      this.loading = "";
      this.displayDealers();
    });
  }

  ngAfterViewInit(): void {
    document.getElementById("admin-dealer")?.focus();
    console.log("ngAfterViewInit");
  }

  ngOnChanges() {
    // changes.prop contains the old and the new value...
    this.displayedDealers.forEach(d => {
      // console.log(d.pa_code);
      // var enrollCell: HTMLTableCellElement = document.querySelector('th[data-pa="d.pa_code"]') || <HTMLTableCellElement>{};
      // enrollCell.textContent = this.getEnrollStatus();
      // d.subscribed = "Yes";
      // this.getSubscription(d.pa_code);
    });
  }

  changeRadio(eventEnroll: Event) {
    this.enrolled = (eventEnroll.target as HTMLInputElement).value;
    this.displayDealers();
  }

  getAllDealerStatus(): void {
    var ii = 0;
    var len = this.displayedDealers.length;
    console.log(this.displayedDealers[0]);
    for (ii = 0; ii < len; ii += 1) {
      if (this.displayedDealers[ii].subscribed == "Y") {
        this.getSingleDealerStatus(this.displayedDealers[ii].pa_code);
        break;
      }
    }
  }

  getSingleDealerStatus(pa_code: string): void {
    this.fordService.getSubscription(pa_code).subscribe(sub => {
      var dealer = this.displayedDealers.find(d => {
        return d.pa_code == pa_code;
      }) || <Dealer>{};
      dealer.subscribed = (sub.status == 404) ? " - " : "Yes";
      // this.sub = sub;
      // this.prices = this.funcs.priceByTier(this.dealer.tech_count, this.dealer.country3);

      // this.loading = false;
      // this.enrolled = new Date(this.sub.createdAt).toDateString();
      // var enrollCell: HTMLTableCellElement = document.querySelector('th[data-pa="d.pa_code"]') || <HTMLTableCellElement>{};
      // enrollCell.textContent = (sub.status == 404) ? " - " : "Yes";
      // this.getAllDealerStatus();
    });
  }

  displayDealers() {
    this.displayedDealers = this.filterDealers();
    // this.getAllDealerStatus();

  }

  // getSubscription(pa_code: string): void {
  //   this.fordService.getSubscription(pa_code).subscribe(sub => {
  //     // this.sub = sub;
  //     // this.prices = this.funcs.priceByTier(this.dealer.tech_count, this.dealer.country3);

  //     // this.loading = false;
  //     // this.enrolled = new Date(this.sub.createdAt).toDateString();
  //     var enrollCell: HTMLTableCellElement = document.querySelector('th[data-pa="d.pa_code"]') || <HTMLTableCellElement>{};
  //     enrollCell.textContent = (sub.status == 404) ? " - " : "Yes";
  //   });
  // }

  private filterDealers = () => {
    return this.dealers.filter(this.isDealer);
  };

  private isDealer = (d: Dealer) => {
    var ciSearch = this.search.toLowerCase();
    var ciName = d.name.toLowerCase();
    return (d.pa_code.indexOf(ciSearch) > -1 || ciName.indexOf(ciSearch) > -1) && this.isEnrolled(d);
  };

  private isEnrolled = (d: Dealer) => {
    if (this.enrolled === "E" && d.subscribed === "Y") {
      return true;
    }
    if (this.enrolled === "U" && d.subscribed === "N") {
      return true;
    }
    if (this.enrolled === "A") {
      return true;
    }
    return false;
  };

  fly(d: Dealer) {
    DealerCurrent.pa_code = d.pa_code;
    DealerCurrent.name = d.name;
    DealerCurrent.tech_count = d.tech_count;
    DealerCurrent.subscribed = d.subscribed;
    DealerCurrent.active = d.active;

    // persist data
    var dealerStore = {
      pa_code: DealerCurrent.pa_code,
      name: DealerCurrent.name,
      tech_count: DealerCurrent.tech_count,
      subscribed: DealerCurrent.subscribed,
      active: DealerCurrent.active
    };
    window.localStorage.setItem("dealer", JSON.stringify(dealerStore));
    this.router.navigateByUrl('/status-enrolled');
  };

  download() {
    var csv = ["PA Code,Name,Country,Techs"];
    this.dealers.filter(this.isDealer).forEach(d => {
      csv.push(`"${d.pa_code}","${d.name}","${d.country3}",${d.tech_count}`);
    });
    var data = new Blob([csv.join("\n")]);
    var a: HTMLAnchorElement = document.createElement("a");
    a.download = "dealer.csv";
    a.href = URL.createObjectURL(data);
    a.click();
  }

  getEnrollStatus(): string{
    // get status for pa_code and update
    return "Yes";
  }
}
