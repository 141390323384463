import { Component } from '@angular/core';
import { Tier } from '../data/tier';
import { PriceTier, PRICE_TIERS } from '../data/price-tier';
import { Router } from '@angular/router';
import { TierService } from '../services/tier.service';
import { PriceTierService } from '../services/price-tier.service';
import { FuncsService } from '../services/funcs.service';

@Component({
  selector: 'app-tiers',
  templateUrl: './tiers.component.html',
  styleUrls: ['./tiers.component.css']
})

export class TiersComponent {
  public loading = "";
  priceTiers = PRICE_TIERS;

  constructor(
    private priceTierService: PriceTierService,
    private router: Router,
    private funcs: FuncsService
  ) {}

  ngOnInit(): void {
    this.funcs.checkAdmin();
    this.loading = "Loading...";

    this.priceTierService.getPriceTiers().subscribe(tiers => {
      this.priceTiers = tiers;
      this.loading = "";
    });
  }

  updateQty(idx: number) {
    this.loading = "Updating...";
    this.priceTierService.updatePriceTier(this.priceTiers[idx]).subscribe(rsp => {
      this.loading = "";
    });
  }
}
