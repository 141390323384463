<main>
<header>
    <!-- <div>
        <p><a href="/home">{{title}}</a></p>
        <p><a href="" (click)="logout()">{{user_name}}</a></p>
    </div> -->
    <div>
        <p><a href="/home">{{title}}</a> <span *ngIf="!prod"> (UAT)</span></p>
        <p><a [routerLink]="" (click)="showNav()">{{user_name}}</a></p>
    </div>
    <nav class="{{navClass}}">
        <ul>
            <li>{{full_name}}</li>
            <li *ngIf="dealer">{{dealer}}</li>
            <li><a [routerLink]="" (click)="logout()">Log Out</a></li>
        </ul>
    </nav>
</header>

<article (click)="closeNavIfOpen()">
<router-outlet></router-outlet>
</article>

<footer (click)="closeNavIfOpen()">
<nav>
  <a routerLink="/contact" id="contact">
      <mat-icon>contact_page</mat-icon> Contact
  </a>
  <a routerLink="/faq" id="faq">
      <mat-icon>quiz</mat-icon> FAQ
  </a>
  <a routerLink="/privacy" id="faq">
      <mat-icon>policy</mat-icon> Privacy
  </a>
</nav>
</footer>
</main>
