import { Component } from '@angular/core';
import { Customer, CustomerCurrent } from '../data/customer';
import { CustomerService } from '../services/customer.service';
import { DealerService } from '../services/dealer.service';
import { FuncsService } from '../services/funcs.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  username = "";
  password = "";
  message = "";

  constructor(
    private customerService: CustomerService,
    private dealerService: DealerService,
    private funcs: FuncsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.funcs.currentCustomer(CustomerCurrent);
    if (CustomerCurrent.authenticated === "Y" && CustomerCurrent.role === "ADM") {
      this.router.navigateByUrl('/admin-home');
      return;
    }
    if (CustomerCurrent.authenticated === "Y") {
      this.router.navigateByUrl('/status-enrolled');
      return;
    }
  }

  checkPw(): void {
    this.message = "";
    this.customerService.getCustomerAuth(this.username, this.password).subscribe(customer => {
      if (customer && customer.authenticated === "Y") {
        this.funcs.setCustomer(customer);
        if (CustomerCurrent.role === "DEALER") {
          this.dealerService.getDealer(CustomerCurrent.pa_code).subscribe(dealer => {
            this.funcs.setDealer(dealer);
            this.router.navigateByUrl('/status-enrolled');
          });
        }
        if (CustomerCurrent.role === "ADM") {
          this.router.navigateByUrl('/admin-home');
        }
      } else {
        this.message = "Incorrect username or password.";
      }
    });
  }
}
