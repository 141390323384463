import { Component } from '@angular/core';
import { Subscription } from '../data/subscription';
import { FordService } from '../services/ford.service';
import { Dealer, DealerCurrent } from '../data/dealer';
import { PriceTier } from '../data/price-tier';
import { PriceTierService } from '../services/price-tier.service';
import { Price } from '../data/price';
import { Router } from '@angular/router';
import { FuncsService } from '../services/funcs.service';

@Component({
  selector: 'app-enroll',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.css']
})
export class EnrollComponent {
  constructor(
    private fordService: FordService,
    private _router: Router,
    private priceTierService: PriceTierService,
    private funcs: FuncsService
  ) {}

  sub: Subscription = {
    createdAt: "",
    siteCode: "3333",
    maxAllowedSessions: 119,
    concurrentSessions: 12,
    expiresAt: "",
    status: 0
  };

  prices: Price = {
    per_tech: 0,
    monthly: 0
  };

  public price = 0;
  public monthly = 0;
  public expires = "";
  dealer: Dealer = DealerCurrent;
  priceTiers: PriceTier[] = [];
  
  ngOnInit(): void {
    this.funcs.currentDealer(this.dealer);
    // this.prices = this.funcs.priceByTier(this.dealer.tech_count, this.dealer.country3);
    this.priceTierService.getPriceTiers().subscribe(priceTiers => {
      this.priceTiers = priceTiers;
      this.priceTiers = this.priceTiers.sort((a, b) => {
        return a.qty - b.qty;
      });

      this.priceTiers.forEach(pt => {
        if (pt.qty < this.dealer.tech_count) {
          this.prices.per_tech = pt.price;
        }
      });
      this.prices.monthly = this.prices.per_tech * this.dealer.tech_count / 12;
      // console.log(this.prices);
      // this.loading = false;
      // console.log(this.priceTiers);
    });

    this.sub.createdAt = "";
    this.sub.siteCode = `${this.dealer.country3}${this.dealer.pa_code}`;
    this.sub.maxAllowedSessions = this.dealer.tech_count * 3;
    this.sub.concurrentSessions = this.dealer.tech_count;
    this.expires = (new Date('2123-01-01')).toISOString();
    this.sub.expiresAt = this.expires;
  }
  
  addSubscription(): void {
    this.fordService.addSubscription(this.sub).subscribe(
      () => this._router.navigateByUrl('/status-enrolled')
    );
  }
}
