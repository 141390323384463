import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Revenue } from '../data/revenue';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private dealerUrl = `${environment.apiUrl}/api/Dealers`;

  constructor(private http: HttpClient) { }

  getMonthlyRevenue(): Observable<Revenue[]> {
    const url = `${this.dealerUrl}/monthly_revenue`;
    return this.http.get<Revenue[]>(url)
      .pipe(
        tap(_ => this.log('fetched monthly revenue')),
        catchError(this.handleError<Revenue[]>('getMonthlyRevenue'))
      );
  }

  getDealerTiers(): Observable<Revenue[]> {
    const url = `${this.dealerUrl}/dealer_tiers`;
    return this.http.get<Revenue[]>(url)
      .pipe(
        tap(_ => this.log('fetched dealer tiers')),
        catchError(this.handleError<Revenue[]>('getDealerTiers'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}