import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.css']
})
export class AutoLoginComponent {
  dealer = "";
  key = "";

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dealer = this.route.snapshot.queryParamMap.get('dealer') || '';
    this.key = this.route.snapshot.queryParamMap.get('key') || '';

    console.log(this.dealer, this.key);

    // if (["E", "U", "A"].indexOf(this.queryEnrolled) > -1) {
    //   this.enrolled = this.queryEnrolled;
    // }
    // this.dealerService.getDealers().subscribe(dealers => {
    //   this.dealers = dealers;
    //   this.loading = "";
    //   this.displayDealers();
    // });
  }
}
