import { Component } from '@angular/core';

@Component({
  selector: 'app-enroll-confirm',
  templateUrl: './enroll-confirm.component.html',
  styleUrls: ['./enroll-confirm.component.css']
})
export class EnrollConfirmComponent {

}
