import { Component } from '@angular/core';
import { ReportService } from '../services/report.service';
import { Revenue } from '../data/revenue';
import { FuncsService } from '../services/funcs.service';

@Component({
  selector: 'app-report-monthly-revenue',
  templateUrl: './report-monthly-revenue.component.html',
  styleUrls: ['./report-monthly-revenue.component.css']
})
export class ReportMonthlyRevenueComponent {
  public loading = "Loading...";
  report: Revenue[] = [];

  constructor(
    private reportService: ReportService,
    private funcs: FuncsService
  ) {}
  
  ngOnInit(): void {
    this.funcs.checkAdmin();
    this.loading = "Loading...";
    
    this.reportService.getMonthlyRevenue().subscribe(report => {
      this.report = report;
      this.loading = "";
    });
  }
}
